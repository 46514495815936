<template>

        <div class="h-100 flex-fill d-flex flex-column">
            <h3 class="text-center font-weight-bolder text-white mt-2">Adoption Interview</h3>

            <!-- <hr style="border-color: #FFAD32;" class="w-100 " /> -->

            <h3 class="text-white font-weight-bolder my-3 h3 text-center">Pledge and What to Expect</h3>

            <p class="text-white mt-2">
                Completing this application is a step in the process to acquiring a puppy from Mongrella Associated Shelter, but it does not guarantee I'll get a puppy from this or any future litter. While it's unlikely, the breeder reserves the right to refuse any placement at any time
            </p>

            <p class="text-white">
                APPROVAL AND DEPOSIT: Mongrella Associated Shelter review applications within 2 business days of receipt. Applications are processed in the order they are received. If your home is a good fit, We will notify you.  Please make this deposit within 48 hours of the approval e-mail. If you make a deposit, you are committing to take this dog to your loving housing and provide it with care
            </p>

            <p class="text-white">
                GOING HOME: We cannot hold the dog for extended times; it is critical for their social development that the litter departs within a reasonable timeframe. If you have a big vacation planned or have events that would conflict with the adopted dog, please know dog cannot be boarded in a kennel until they have received their full immunizations and their rabies vaccination which cannot be done until 3 months of age
            </p>

            <p class="text-white">
                REGISTRATION PAPERS: Our excellent family companions do not come with registration papers to any registry. You are free to register your dog with the AKC, UKC or CKC under ILP or single registration status should you desire to enter events and competitions.
            </p>

            <hr style="border-color: #FFAD32;" class="w-100" />

            <p class="text-white font-weight-bolder h4">
                Please provide any other relevant information you feel would assist us in placing a pet in your home:
            </p>

            <textarea style="border: 2px solid #FFAD32;background-color: transparent;color: white;" class="mt-1 p-1" rows="3" v-model="input.additional_info"></textarea>
            
            <!-- <h5 class="text-white font-weight-bolder mt-2 h4">Your Signature</h5>

            <textarea style="border: 2px solid #FFAD32;background-color: transparent;color: white;" class="mt-1 p-1" rows="3"></textarea> -->
            
            <div class="mt-2">
                <b-form-checkbox
                    v-model="status"  
                >
                    <div class="text-white">
                        I agree to the terms & conditions and privacy policy as set out by the user agreement.
                    </div>
                </b-form-checkbox>
            </div>
 
            <button class="btn text-white flex-fill mt-3" @click="nextStep()" style="background-color: #187294;height:50px;font-size: 18px;">
                Submit
            </button> 
        </div>

</template>

<script>

import { BFormCheckbox } from 'bootstrap-vue'
import Swal from 'sweetalert2'

export default {
    components: {
        BFormCheckbox
    },
    methods: {
        nextStep(){
            if(this.status)
                this.$emit('next', this.input)
            else {
                Swal.fire('You need to agree with the terms and agreement')
            }
        }
    },
    data(){
        return {
            input: {
                additional_info: ''
            },
            status: false
        }
    }
}

</script>

<style>
    .auth-page .btn {
        border-radius: 20px;
    }

    input:focus {
        color: white !important;
    }

    .input-group span {
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        font-size: 24px;
        color: #1F9BCD;
        background-color: transparent;
    }
    .input-group input {
        margin-left: 0;
        padding-left: 0;
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        height: 50px;
        color: white;
        background-color: transparent !important;
    } 
</style>