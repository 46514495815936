<template>
    <div>

        <div class="h-100 flex-fill d-flex flex-column">
            <h3 class="text-center font-weight-bolder text-white mt-2">Adoption Interview</h3>

            <hr style="border-color: #FFAD32;" class="w-100 " />

            <h3 class="text-white font-weight-bolder mt-3 h3">Interview</h3>

            <p class="text-white mt-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vehicula porttitor magna a porttitor. Fusce
                nibh lectus, faucibus ut imperdiet nec,

                Nullam a libero vitae lorem varius suscipit ac a mi.
                Donec feugiat velit eu justo suscipit cursus.
                In quis lectus vel turpis consectetur interdum.
                Curabitur a dui in nisi malesuada fermentum.
                Suspendisse mattis libero eget risus vulputate, ac tincidunt ipsum congue.
                Nunc eget dui dapibus magna vehicula finibus id vel arcu.

                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean posuere massa odio, sit amet tristique massa
                interdum sed. Donec rhoncus est quis nisi pellentesque,
            </p>

            <p class="text-white h4 mt-2 text-center">
                please wait for our caretaker to send you the interview
                schedule that will be directly sent to your Email!
            </p>

            <div class="btn mt-3"
                style="padding:.75em 3em;margin-top:.5em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 15px;">
                <div class="h2 text-white mb-0" @click="nextStep()">Thank You</div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    methods: {
        nextStep() {
            this.$emit('next')
        }
    }
}

</script>

<style>
.auth-page .btn {
    border-radius: 20px;
}

input:focus {
    color: white !important;
}

.input-group span {
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    font-size: 24px;
    color: #1F9BCD;
    background-color: transparent;
}

.input-group input {
    margin-left: 0;
    padding-left: 0;
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    height: 50px;
    color: white;
    background-color: transparent !important;
}</style>