<template>

    <div class="h-100 flex-fill d-flex flex-column">
        <h3 class="text-center font-weight-bolder text-white mt-3">Adoption Interview</h3>

        <!-- <hr style="border-color: #FFAD32;" class="w-100 " /> -->

        <h3 class="text-white font-weight-bolder mt-3 h4">Terms & Condition</h3>

        <p class="text-white">
            Thank you for considering pet adoption through our platform. To initiate the process, adopters must be at least 18 years old, providing proof of identification and 	residence. Completing the application involves sharing accurate information and necessary documentation. In certain cases, a home visit may be required to ensure a safe environment. <br/> <br/>
Adoption fees, covering veterinary expenses, are non-refundable and should be paid before finalization. Adopters are responsible for ongoing veterinary care, behavioral training, and providing proper shelter and nutrition. Our shelter association encourages a commitment to the well-being of the adopted pet, including periodic updates on their status. Compliance with local laws is mandatory, and the agency reserves the right to deny requests. By submitting an adoption request, you acknowledge these terms, signifying your dedication to offering a loving home to our pets.
        </p>

        <h3 class="font-weight-bolder text-white mt-3">How to Appeal for Adoption</h3>
        <h3 class="text-white font-weight-bolder mt-1 h4">Step 2 - Give Your Reason for Adopting</h3>

        <div class="d-flex align-items-center justify-content-center mt-auto my-1 py-2" style="gap:10px">
            <div style="width: 10px;height: 10px;background-color: #C4C4C4;border-radius: 50%;">
            </div>
            <div style="width: 10px;height: 10px;background-color: #FFAD32;border-radius: 50%;">
            </div>
            <div style="width: 10px;height: 10px;background-color: #C4C4C4;border-radius: 50%;">
            </div>
            <div style="width: 10px;height: 10px;background-color: #C4C4C4;border-radius: 50%;">
            </div>
        </div>

        <button class="btn text-white flex-fill" @click="nextStep()" style="background-color: #187294;height:50px;font-size: 18px;">
            Im Ready!
        </button> 

    </div>

</template>

<script>

export default {
    methods: {
        nextStep(){
            this.$emit('next')
        }
    }
}

</script>