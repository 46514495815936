<template>

        <div class="h-100 flex-fill d-flex flex-column">
            <!-- <h3 class="text-center font-weight-bolder text-white mt-2">Adoption Interview</h3>

            <hr style="border-color: #FFAD32;" class="w-100 " /> -->

            <h3 class="text-white font-weight-bolder my-3 h3 text-center">Step 3 - Describe Your Housing Capability</h3>

            <div class="mt-2">
                <label class="h4 text-white">Type of housing</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.type_of_housing">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.type_of_housing && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div> 
            
            <div class="mt-2">
                <label class="h4 text-white">Ownership of house</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.ownership_of_house">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.ownership_of_house && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div> 
            
            <div class="mt-2">
                <label class="h4 text-white">Do you have a backyard or areas for pet?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.is_have_backyard">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.is_have_backyard && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div> 
            
            <div class="mt-2">
                <label class="h4 text-white">How many adults present in your home?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.how_many_adults">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.how_many_adults && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div> 
            
            <div class="mt-2">
                <label class="h4 text-white">How many minor children present in your home?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.how_many_childrens">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.how_many_childrens && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div> 
            
            <div class="mt-2">
                <label class="h4 text-white">Does anyone in the home have allergies to pet?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.anyone_have_allergies">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.anyone_have_allergies && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div> 
            
            <div class="mt-2">
                <label class="h4 text-white">Where will the pet spend most of its time?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.where_pet_spend_time">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.where_pet_spend_time && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div> 
            
            <div class="mt-2">
                <label class="h4 text-white">Will your pet be inside pet or outside pet?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.inside_or_outside_pet">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.inside_or_outside_pet && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div> 
            
            <div class="mt-2">
                <label class="h4 text-white">How many hours will the pet left alone?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.how_long_pet_left">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.how_long_pet_left && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div> 

            <button class="btn text-white flex-fill mt-3" @click="nextStep()" style="background-color: #187294;height:50px;font-size: 18px;">
                Next
            </button> 
        </div>

</template>

<script>

export default {
    props: {
        payload : {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        valid(){
            return { 
                type_of_housing : this.input.type_of_housing.length > 0,
                ownership_of_house : this.input.ownership_of_house.length > 0,
                is_have_backyard : this.input.is_have_backyard.length > 0,
                how_many_adults : this.input.how_many_adults.length > 0,
                how_many_childrens : this.input.how_many_childrens.length > 0,
                anyone_have_allergies : this.input.anyone_have_allergies.length > 0,
                where_pet_spend_time : this.input.where_pet_spend_time.length > 0,
                inside_or_outside_pet : this.input.inside_or_outside_pet.length > 0,
                how_long_pet_left : this.input.how_long_pet_left.length > 0,
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    },
    methods: {
        nextStep(){
            this.isSubmit = true
            if(this.allValid){
                this.$emit('next', this.input)
            }
        }
    },
    mounted(){
        this.input = {...this.input, ...this.payload}
    },
    data(){
        return {
            input: {
                type_of_housing: '',
                ownership_of_house: '',
                is_have_backyard: '',
                how_many_adults: '',
                how_many_childrens: '',
                anyone_have_allergies: '',
                where_pet_spend_time: '',
                inside_or_outside_pet: '',
                how_long_pet_left: ''
            },
            isSubmit: false
        }
    }
}

</script>

<style>
    .auth-page .btn {
        border-radius: 20px;
    }

    input:focus {
        color: white !important;
    }

    .input-group span {
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        font-size: 24px;
        color: #1F9BCD;
        background-color: transparent;
    }
    .input-group input {
        margin-left: 0;
        padding-left: 0;
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        height: 40px !important;
        color: white;
        background-color: transparent !important;
    }  
    select.form-control option, select.form-control:active option {
        color:white !important;
        background-color: #1B628C !important;
    }
</style>