<template>

        <div class="h-100 flex-fill d-flex flex-column">
            <!-- <h3 class="text-center font-weight-bolder text-white mt-2">Adoption Interview</h3>

            <hr style="border-color: #FFAD32;" class="w-100 " /> -->

            <h3 class="text-white font-weight-bolder my-3 h3 text-center">Step 2 - Give Your Reason for Adopting</h3>

            <div class="mt-2">
                <label class="h4 text-white">Why are you interested in adopting me?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.reason_adopting">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.reason_adopting && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div>  

            <div class="mt-2">
                <label class="h4 text-white">Is this your first pet?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.is_first_pet">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.is_first_pet && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div>   

            <div class="mt-2">
                <label class="h4 text-white">Do you have other pets in the home?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.is_other_pet">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.is_other_pet && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div>  

            <div class="mt-2">
                <label class="h4 text-white">Do you already have a veterinarian?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.is_have_vet">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.is_have_vet && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div>  

            <div class="mt-2">
                <label class="h4 text-white">What do you plan on doing with your pet?</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" placeholder="" v-model="input.what_plan_to_pet">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.what_plan_to_pet && isSubmit" style="margin-top: .5em;">Input not valid</div>
            </div>  

            <button class="btn text-white flex-fill mt-3" @click="nextStep()" style="background-color: #187294;height:50px;font-size: 18px;">
                Next
            </button> 
        </div>

</template>

<script>

export default {
    props: {
        payload : {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        valid(){
            return { 
                reason_adopting : this.input.reason_adopting.length > 0,
                is_first_pet : this.input.is_first_pet.length > 0,
                is_other_pet : this.input.is_other_pet.length > 0,
                is_have_vet : this.input.is_have_vet.length > 0,
                what_plan_to_pet : this.input.what_plan_to_pet.length > 0,
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    },
    methods: {
        nextStep(){
            this.isSubmit = true
            if(this.allValid){
                this.$emit('next', this.input)
            }
        }
    },
    mounted(){
        this.input = {...this.input, ...this.payload}
    },
    data(){
        return {
            input: {
                reason_adopting: '',
                is_first_pet: '',
                is_other_pet: '',
                is_have_vet: '',
                what_plan_to_pet: ''
            },
            isSubmit : false
        }
    }
}

</script>

<style>
    .auth-page .btn {
        border-radius: 20px;
    }

    input:focus {
        color: white !important;
    }

    .input-group span {
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        font-size: 24px;
        color: #1F9BCD;
        background-color: transparent;
    }
    .input-group input {
        margin-left: 0;
        padding-left: 0;
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        height: 40px !important;
        color: white;
        background-color: transparent !important;
    }  
    select.form-control option, select.form-control:active option {
        color:white !important;
        background-color: #1B628C !important;
    }
</style>