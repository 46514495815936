<template>

        <div class="h-100 flex-fill d-flex flex-column"> 
            <!-- <h3 class="text-center font-weight-bolder text-white mt-2">Adoption Interview</h3> -->

            <!-- <hr style="border-color: #FFAD32;" class="w-100 " /> -->

            
            <h3 class="text-white font-weight-bolder my-3 h3 text-center">Step 1 - Fill Personal Information</h3>

            <div class="mt-2">
                <label class="h4 text-white">Name</label>
                <div class="input-group">
                    <span class="input-group-text">
                        <i class="bx bx-user text-white"></i>
                    </span>
                    <input class="form-control" placeholder="Name" v-model="input.name">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.name && isSubmit" style="margin-top: .5em;">Name not valid</div>
            </div>
            
            <div class="mt-2">
                <label class="h4 text-white">Age (Years)</label>
                <div class="input-group">
                    <span class="input-group-text"></span>
                    <input class="form-control" type="number" placeholder="Age" v-model="input.age">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.age && isSubmit" style="margin-top: .5em;">Age not valid</div>
            </div>
            
            <div class="mt-2">
                <label class="h4 text-white">Address</label>
                <div class="input-group">
                    <span class="input-group-text">
                        <i class="bx bxs-home text-white"></i>
                    </span>
                    <input class="form-control" placeholder="Address" v-model="input.address">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.address && isSubmit" style="margin-top: .5em;">Address not valid</div>
            </div>
            
            <div class="mt-2">
                <label class="h4 text-white">Occupation</label>
                <div class="input-group">
                    <span class="input-group-text">
                        <i class="bx bxs-briefcase text-white"></i>
                    </span>
                    <input class="form-control" placeholder="Occupation" v-model="input.occupation">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.occupation && isSubmit" style="margin-top: .5em;">Occupation not valid</div>
            </div>
            
            <div class="mt-2">
                <label class="h4 text-white">Mobile Number</label>
                <div class="d-flex" style="gap:10px 15px"> 
                    <div class="input-group">
                        <span class="input-group-text">
                            <i class="bx bx-phone text-white"></i>
                        </span>
                        <input class="form-control" type="number" placeholder="Mobile Number" v-model="input.mobile_number">
                    </div>
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.mobile_number && isSubmit" style="margin-top: .5em;">Mobile Number not valid</div>
            </div>

            <button class="btn text-white flex-fill mt-3" @click="nextStep()" style="background-color: #187294;height:50px;font-size: 18px;">
                Next
            </button> 
        </div>

</template>

<script>

export default {
    props: {
        payload : {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        valid(){
            return {
                name : this.input.name.length > 0,
                age : this.input.age.length > 0,
                address : this.input.address.length > 0,
                occupation : this.input.occupation.length > 0,
                mobile_number : this.input.mobile_number.length > 0,
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    },
    methods: {
        nextStep(){
            this.isSubmit = true
            if(this.allValid){
                this.$emit('next', this.input)
            }
        }
    },
    mounted(){
        this.input = {...this.input, ...this.payload}
    },
    data(){
        return {
            input: {
                name: '',
                age: '',
                address: '',
                occupation: '',
                mobile_number: ''
            },
            isSubmit : false
        }
    }
}

</script>

<style>
    .auth-page .btn {
        border-radius: 20px;
    }

    input:focus {
        color: white !important;
    }

    .input-group span {
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        font-size: 24px;
        color: #1F9BCD;
        background-color: transparent;
    }
    .input-group input {
        margin-left: 0;
        padding-left: 0;
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        height: 40px !important;
        color: white;
        background-color: transparent !important;
    }  
    select.form-control option, select.form-control:active option {
        color:white !important;
        background-color: #1B628C !important;
    }
</style>