<template>
    <div class="px-3 pt-3 d-flex flex-column flex-fill">
        <div class="">
            <div @click="goBack()" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </div>
        </div>

        <Terms1 class="" @next="nextStep" v-if="step == 0" />
        <Terms2 class="" @next="nextStep" v-else-if="step == 2" />
        <Terms3 class="" @next="nextStep" v-else-if="step == 4" />
        <Terms4 class="" @next="nextStep" v-else-if="step == 6" />
        
        <Form1 :payload="payload" class="" @next="nextStep" v-else-if="step == 1" />
        <Form2 :payload="payload" class="" @next="nextStep" v-else-if="step == 3" />
        <Form3 :payload="payload" class="" @next="nextStep" v-else-if="step == 5" />
        <Form4 :payload="payload" class="" @next="submit" v-else-if="step == 7" />

        <Final @next="done()" v-else-if="step == 8" />

    </div>
</template>

<script>

import Terms1 from './form/terms-1'
import Terms2 from './form/terms-2'
import Terms3 from './form/terms-3'
import Terms4 from './form/terms-4'

import Form1 from './form/form-1'
import Form2 from './form/form-2'
import Form3 from './form/form-3'
import Form4 from './form/form-4'

import Final from './form/final'

import Cookies from 'universal-cookie'
let cookies = new Cookies()

import store from '@/store'

export default {
    components: {
        Terms1, Form1,
        Terms2, Form2,
        Terms3, Form3,
        Terms4, Form4,
        Final
    },
    methods: {
        goBack(){
            if(this.step == 0){

            } else {
                this.step = this.step - 1
            }
        },
        nextStep(val){
            if(val){
                this.payload = {...this.payload, ...val}
            }
            this.step += 1
        },
        submit(val){
            if(val){
                this.payload = {...this.payload, ...val}
            }
            let formData = new FormData()
            formData.append('shelter_id', this.shelter_id)
            formData.append('sha_id', this.sha_id)
            formData.append('user_id', cookies.get('token'))
            formData.append('datas', JSON.stringify(this.payload))

            store.dispatch('shelter/RequestAdoption', formData).then((res) => {
                // this.step += 1
                this.$router.push('/shelter/adopt/summary/'+res.id)
            })
        },
        done(){
            this.$router.push('/shelter/adopt/'+this.shelter_id)
        }
    },
    data(){
        return {
            shelter_id : this.$route.params.shelter_id,
            sha_id: this.$route.params.id,
            step: 0,
            payload: {}
        }
    }
}

</script>